import Bugsnag from '@bugsnag/js'
import React from 'react'
import dynamic from 'next/dynamic'

// we do this to avoid ssr, but loading dynamically can fail
const DynamicApp = dynamic(
  () =>
    import('components/app').catch(error => {
      // TODO(ibash) consider doing a hard reload on intervals to try and get
      // to a good state...
      // window.location.reload(true);
      Bugsnag.notify(error)
      throw error
    }),
  {
    loading({ error, isLoading, pastDelay }) {
      if (error) {
        return (
          <div style={{ padding: '8px' }}>
            <p>
              Summon failed to load, please reach out to{' '}
              <a href="mailto:islam@summon.app">islam@summon.app</a> for help.
            </p>
          </div>
        )
      }
      return (
        <div style={{ padding: '8px' }}>
          <p>Loading...</p>
        </div>
      )
    },
    ssr: false
  }
)

export default DynamicApp
